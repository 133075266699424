import { AttributeOption } from '../types/productVariant';
import { LanguageKeys, uiSiteConfig } from './uiConfig';

export const FRAME_SHAPE_ATTRIBUTE: AttributeOption = 'attrFrameShape';
export const FRAME_SHAPE_ATTRIBUTE_2: AttributeOption = 'FRAMETYPE';
export const FRAME_MATERIAL_ATTRIBUTE: AttributeOption = 'attrFrameMaterial';
export const COLOR_ATTRIBUTE: AttributeOption = 'attrMarketingColor';
export const SIZE_ATTRIBUTE: AttributeOption = 'attrSize';
export const WHEEL_SIZE_ATTRIBUTE: AttributeOption = 'attrWheelSize';
export const FRAME_SIZE_ATTRIBUTE: AttributeOption = 'attrFrameSize';
export const EXACT_RANGE_ATTRIBUTE: AttributeOption = 'attrExactRange';
export const ENGINE_DESCRIPTION_ATTRIBUTE: AttributeOption = 'attrEngineDescription';
export const PRODUCT_LINE_ATTRIBUTE: AttributeOption = 'attrProductLine';
export const REQUIRED_BATTERY_ATTRIBUTE: AttributeOption = 'requiredBattery';
export const OPTIONAL_BATTERY_ATTRIBUTE: AttributeOption = 'optionalBattery';
export const SECONDARY_COLOR_ATTRIBUTE: AttributeOption = 'attrSecondaryColor';
export const REAR_DERAILLEUR_DESCRIPTION_ATTRIBUTE: AttributeOption =
    'attrRearDerailleurDescription';
export const FRONT_TIRE_ATTRIBUTE: AttributeOption = 'attrFrontTire';
export const REAR_TIRE_ATTRIBUTE: AttributeOption = 'attrRearTire';
export const BRAKE_DESCRIPTION_ATTRIBUTE: AttributeOption = 'attrBrakeDescription';
export const FRONT_FORK_DESCRIPTION_ATTRIBUTE: AttributeOption = 'FrontForkDescription';

export const ADDITIONAL_ATTRIBUTES: AttributeOption[] = [
    REAR_DERAILLEUR_DESCRIPTION_ATTRIBUTE,
    FRONT_TIRE_ATTRIBUTE,
    REAR_TIRE_ATTRIBUTE,
    BRAKE_DESCRIPTION_ATTRIBUTE,
    ENGINE_DESCRIPTION_ATTRIBUTE,
    FRONT_FORK_DESCRIPTION_ATTRIBUTE,
];

const getBrandAttributeCodeFromOption = ({
    language,
    attributeOption,
}: {
    language: string;
    attributeOption: AttributeOption;
}) => {
    const productAttributesMap = (uiSiteConfig.Product.attributesKeyMap?.[
        language.toLowerCase() as LanguageKeys
    ] ??
        uiSiteConfig.Product.attributesKeyMap?.default ??
        {}) as {
        [option in AttributeOption]: string;
    };
    return productAttributesMap[attributeOption] ?? attributeOption;
};

export const getBrandAttributeOptionFromCode = ({
    language,
    attributeCode,
}: {
    language: string;
    attributeCode: string;
}): AttributeOption => {
    const productAttributesMap = (uiSiteConfig.Product.attributesKeyMap?.[
        language.toLowerCase() as LanguageKeys
    ] ??
        uiSiteConfig.Product.attributesKeyMap?.default ??
        {}) as {
        [option in AttributeOption]: string;
    };

    const attributeKey = Object.entries(productAttributesMap).find(
        ([, code]) => code === attributeCode
    )?.[0] as AttributeOption;

    return (getBrandAttributeCodeFromOption({
        language,
        attributeOption: attributeKey,
    }) ?? attributeCode) as AttributeOption;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TSortingFunction = null | ((a: any, b: any) => number);

export const getCustomAttributeOptionSorting = (
    attributeCode: AttributeOption
): TSortingFunction => {
    const { attributeValueSortingStrategy } = uiSiteConfig.Product;

    if (attributeValueSortingStrategy && attributeCode in attributeValueSortingStrategy) {
        switch (attributeValueSortingStrategy[attributeCode as AttributeOption]) {
            case 'asc': {
                return (a, b) => a.value.localeCompare(b.value);
            }
            default: {
                return null;
            }
        }
    }

    return null;
};
export default getBrandAttributeCodeFromOption;
