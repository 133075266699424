import React, { ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react';
import styled, { useTheme } from 'styled-components';

export interface WrapperColorContextProps {
    pageBackgroundColor?: string;
    productTileBackgroundColor?: {
        initial?: string;
        hover?: string;
    };
}

export interface WrapperColorProviderProps {
    children: ReactNode;
    pageType?: string;
}

const StyledMain = styled.main<{ $wrapperColor?: string }>`
    ${({ $wrapperColor }) => $wrapperColor && `background: ${$wrapperColor};`}
`;

const WrapperColorContext = createContext<WrapperColorContextProps>({
    pageBackgroundColor: undefined,
    productTileBackgroundColor: undefined,
});

export const WrapperColorProvider = ({ children, pageType }: WrapperColorProviderProps) => {
    const theme = useTheme();
    const [pageBackgroundColor, setPageBackgroundColor] =
        useState<WrapperColorContextProps['pageBackgroundColor']>(undefined);
    const [productTileBackgroundColor, setProductTileBackgroundColor] =
        useState<WrapperColorContextProps['productTileBackgroundColor']>(undefined);

    useEffect(() => {
        const wrapperProductColor =
            !!theme.colors.wrapperProduct && theme.colors.wrapperProduct !== 'undefined'
                ? theme.colors.wrapperProduct
                : undefined;
        const wrapperCategoryColor =
            !!theme.colors.wrapperCategory && theme.colors.wrapperCategory !== 'undefined'
                ? theme.colors.wrapperCategory
                : undefined;
        const wrapperPublicationColor =
            !!theme.colors.wrapperPublication && theme.colors.wrapperPublication !== 'undefined'
                ? theme.colors.wrapperPublication
                : undefined;
        const pageColor =
            pageType === 'detail' && wrapperProductColor
                ? wrapperProductColor
                : pageType === 'product_listing_page' && wrapperCategoryColor
                  ? wrapperCategoryColor
                  : pageType === 'content' && wrapperPublicationColor
                    ? wrapperPublicationColor
                    : undefined;
        const tileColor =
            pageType === 'detail' || pageType === 'product_listing_page'
                ? {
                      initial:
                          !theme.colors.wrapperTileColor ||
                          theme.colors.wrapperTileColor === 'undefined'
                              ? undefined
                              : theme.colors.wrapperTileColor,
                      hover:
                          !theme.colors.wrapperTileColorHover ||
                          theme.colors.wrapperTileColorHover === 'undefined'
                              ? undefined
                              : theme.colors.wrapperTileColorHover,
                  }
                : undefined;
        setPageBackgroundColor(pageColor);
        setProductTileBackgroundColor(tileColor);
    }, [
        pageType,
        theme.colors.wrapperBody,
        theme.colors.wrapperCategory,
        theme.colors.wrapperProduct,
        theme.colors.wrapperTileColor,
        theme.colors.wrapperTileColorHover,
        theme.colors.wrapperPublication,
    ]);

    const memoizedValues = useMemo(
        () => ({
            pageBackgroundColor,
            productTileBackgroundColor,
        }),
        [pageBackgroundColor, productTileBackgroundColor]
    );

    return (
        <WrapperColorContext.Provider value={memoizedValues}>
            <StyledMain $wrapperColor={pageBackgroundColor}>{children}</StyledMain>
        </WrapperColorContext.Provider>
    );
};

export const useWrapperColor = () => {
    const context = useContext(WrapperColorContext);

    if (!context) throw new Error(`useWrapperColor must be used within a WrapperColorProvider`);

    return context;
};
