import React, { CSSProperties, FC } from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { BASE_URL } from '../constants/tokens';
import { CodeMark, Document, LinkMark, Node, TextNode } from '../types/storyBlokComponentTypes';
import getResponsiveGatsbyImageData, {
    ImageLayout,
} from '../services/getResponsiveGatsbyImageData';
import isExternalUrl from '../services/isExternalUrl';
import createLink from '../services/createLink';
import useLocale from '../hooks/useLocale';
import UHeading, { TitleVariant } from './ui/UHeading';
import GatsbyLink from './GatsbyLink';
import SalesforceForm from './SalesforceForm';

interface Props {
    document: Document;
    setHeadingAsH1?: boolean;
    linkDoNotEnsureCorrectCountryLanguage?: boolean;
    linkForceHardRefresh?: boolean;
}

const BLOCK_QUOTE = 'blockquote';
const QUOTE = '"';

/**
 * A general purpose renderer for the document object types that storyblok passes. (WYSIWYG fields)
 */
const ContentRenderer: FC<Props> = ({
    document,
    linkDoNotEnsureCorrectCountryLanguage,
    linkForceHardRefresh,
    ...rest
}) => {
    const hasQuote = document?.content?.some((node: Node) => node.type === BLOCK_QUOTE);

    return (
        <>
            {!hasQuote &&
                document?.content?.map((node, i) => (
                    <NodeRenderer
                        key={i}
                        node={node}
                        linkDoNotEnsureCorrectCountryLanguage={
                            linkDoNotEnsureCorrectCountryLanguage
                        }
                        linkForceHardRefresh={linkForceHardRefresh}
                        {...rest}
                    />
                ))}
            {hasQuote && (
                <TextBlockWithQuote>
                    {document?.content?.map((node, i) => (
                        <TextBlockWrapper
                            key={i}
                            $isQuote={node.type === BLOCK_QUOTE}
                            $nextIsQuote={document?.content[i + 1]?.type === BLOCK_QUOTE}
                        >
                            <NodeRenderer
                                node={node}
                                linkDoNotEnsureCorrectCountryLanguage={
                                    linkDoNotEnsureCorrectCountryLanguage
                                }
                                linkForceHardRefresh={linkForceHardRefresh}
                                {...rest}
                            />
                        </TextBlockWrapper>
                    ))}
                </TextBlockWithQuote>
            )}
        </>
    );
};

export default ContentRenderer;

const NodeRenderer: FC<{
    node: Node;
    previousNode?: Node;
    setHeadingAsH1?: boolean;
    linkDoNotEnsureCorrectCountryLanguage?: boolean;
    linkForceHardRefresh?: boolean;
}> = ({
    node,
    previousNode,
    setHeadingAsH1,
    linkDoNotEnsureCorrectCountryLanguage,
    linkForceHardRefresh,
    ...rest
}) => {
    switch (node.type) {
        case 'hard_break':
        case 'code':
        case 'text':
            return (
                <StyledText {...rest}>
                    <TextNodeRenderer
                        node={node}
                        linkDoNotEnsureCorrectCountryLanguage={
                            linkDoNotEnsureCorrectCountryLanguage
                        }
                        linkForceHardRefresh={linkForceHardRefresh}
                        {...rest}
                    />
                </StyledText>
            );
        case 'paragraph':
            return (
                <StyledText {...rest}>
                    {node?.content?.map((paragraphNode, i) => {
                        switch (paragraphNode.type) {
                            case 'text':
                                return (
                                    <TextNodeRenderer
                                        key={i}
                                        node={paragraphNode}
                                        linkDoNotEnsureCorrectCountryLanguage={
                                            linkDoNotEnsureCorrectCountryLanguage
                                        }
                                        linkForceHardRefresh={linkForceHardRefresh}
                                        {...rest}
                                    />
                                );
                            case 'image':
                                return (
                                    <NodeRenderer
                                        key={i}
                                        node={{ ...paragraphNode.attrs, type: 'image' }}
                                    />
                                );
                            default:
                                return (
                                    <TextNodeRenderer
                                        key={i}
                                        node={paragraphNode}
                                        linkDoNotEnsureCorrectCountryLanguage={
                                            linkDoNotEnsureCorrectCountryLanguage
                                        }
                                        linkForceHardRefresh={linkForceHardRefresh}
                                        {...rest}
                                    />
                                );
                        }
                    })}
                </StyledText>
            );
        case 'heading':
            return (
                <StyledHeader
                    color="currentColor"
                    variant={
                        `${
                            setHeadingAsH1 ? 'heading1' : `heading${node.attrs.level}`
                        }` as TitleVariant
                    }
                >
                    {node.content?.map((textNode, i) => (
                        <TextNodeRenderer
                            key={i}
                            node={textNode}
                            linkDoNotEnsureCorrectCountryLanguage={
                                linkDoNotEnsureCorrectCountryLanguage
                            }
                            linkForceHardRefresh={linkForceHardRefresh}
                        />
                    ))}
                </StyledHeader>
            );
        case 'bullet_list':
        case 'ordered_list': {
            const ListComponent = node.type === 'bullet_list' ? UnorderedList : OrderedList;
            return (
                <ListWrapper>
                    <ListComponent>
                        {node?.content?.map((listItem, i) => (
                            <li key={i}>
                                {listItem.content.map((listNode, j) => (
                                    <NodeRenderer
                                        key={j}
                                        node={listNode}
                                        linkDoNotEnsureCorrectCountryLanguage={
                                            linkDoNotEnsureCorrectCountryLanguage
                                        }
                                        linkForceHardRefresh={linkForceHardRefresh}
                                    />
                                ))}
                            </li>
                        ))}
                    </ListComponent>
                </ListWrapper>
            );
        }
        case 'image': {
            const { src, alt } = 'attrs' in node ? node.attrs : node;
            const responsiveGatsbyImageData = getResponsiveGatsbyImageData(
                src.replace(/^https?:/, ''),
                {
                    layout: ImageLayout.Constrained,
                }
            );
            return responsiveGatsbyImageData ? (
                <div>
                    <GatsbyImage alt={alt} image={responsiveGatsbyImageData} />
                </div>
            ) : null;
        }
        case BLOCK_QUOTE:
            return (
                <StyledBlockQuote>
                    {node?.content?.map(quoteNode =>
                        quoteNode?.content?.map((content, i) => (
                            <strong key={i}>
                                {QUOTE}
                                <TextNodeRenderer
                                    node={content}
                                    linkDoNotEnsureCorrectCountryLanguage={
                                        linkDoNotEnsureCorrectCountryLanguage
                                    }
                                    linkForceHardRefresh={linkForceHardRefresh}
                                />
                                {QUOTE}
                            </strong>
                        ))
                    )}
                </StyledBlockQuote>
            );

        default:
            // @ts-ignore
            if (node.type !== 'horizontal_rule') {
                /* eslint-disable-next-line */ /* @ts-ignore */
                console.warn(`NodeRenderer: unknown node type ${node.type}`);
            }
            return null;
    }
};

export const TextNodeRenderer: FC<{
    node: TextNode;
    linkDoNotEnsureCorrectCountryLanguage?: boolean;
    linkForceHardRefresh?: boolean;
}> = ({ node, linkDoNotEnsureCorrectCountryLanguage, linkForceHardRefresh }) => {
    const { country, language } = useLocale();
    if (node.type === 'hard_break' || node.type === 'image') {
        return <br />;
    }

    const linkMark = node.marks?.find((mark): mark is LinkMark => mark.type === 'link');
    const codeMark = node.marks?.find((mark): mark is CodeMark => mark.type === 'code');

    const style: CSSProperties = {};
    for (const mark of node.marks ?? []) {
        switch (mark.type) {
            case 'bold':
                style.fontWeight = 'bold';
                break;
            case 'italic':
                style.fontStyle = 'italic';
                break;
            case 'underline':
                style.textDecoration = 'underline';
                break;
            case 'strike':
                style.textDecoration = 'line-through';
                break;
            default:
                break;
        }
    }

    if (linkMark) {
        const href = linkMark?.attrs?.href ?? '';
        const linkType = linkMark?.attrs?.linktype ?? 'url';

        if (linkType === 'email') {
            return (
                <a href={`mailto:${href}`} style={style} target={linkMark?.attrs?.target}>
                    {node.text}
                </a>
            );
        }

        if (isExternalLink(linkType, href)) {
            return (
                <a href={href} style={style} target={linkMark?.attrs?.target}>
                    {node.text}
                </a>
            );
        }

        let linkTo = createLink(linkMark?.attrs, country, language);
        if (linkDoNotEnsureCorrectCountryLanguage) {
            linkTo = href;
        }

        if (!linkTo) {
            return <span style={style}>{node.text}</span>;
        }

        if (linkForceHardRefresh) {
            // This transformes the gatsby link to a non-relative anchor link to force a page refresh.
            const formattedHref = BASE_URL + linkTo;
            return (
                <a href={formattedHref} style={style} target={linkMark?.attrs.target}>
                    {node.text}
                </a>
            );
        }

        return (
            <GatsbyLink to={linkTo} style={style} target={linkMark?.attrs?.target}>
                {node.text}
            </GatsbyLink>
        );
    }

    if (codeMark && node.text) {
        switch (getElementType(node.text)) {
            case 'iframe':
                return (
                    <IFrameContainer
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{ __html: node.text }}
                    />
                );
            case 'salesforceform':
                return <SalesforceForm />;
            default:
                // eslint-disable-next-line react/no-danger
                return <div dangerouslySetInnerHTML={{ __html: node.text }} />;
        }
    }

    if (Object.keys(style).length === 0) {
        return <>{node.text}</>;
    }

    return <span style={style}>{node.text}</span>;
};

const isExternalLink = (linkType: string, href: string): boolean =>
    (linkType === 'url' || linkType === 'asset') && isExternalUrl(href);

const getElementType = (text: string) =>
    // the first index returns the full match. The second index returns it without the matching characters (< \s >).
    // also it would be nicer if we could use positive lookbacks, but these aren't supported in safari 💩.
    /<([\w]+)(\s|>)/.exec(text)?.[1];

const StyledHeader = styled(UHeading)`
    padding: 0;

    &:not(:last-child) {
        margin-bottom: ${({ theme }) => theme.sizeGridSpacingRem16};
    }
`;

const StyledText = styled.p`
    padding: 0;
    margin-block-start: 0;
    margin-block-end: 0;

    &:not(:last-child) {
        margin-bottom: ${({ theme }) => theme.sizeGridSpacingRem16};
    }
`;

const StyledBlockQuote = styled.blockquote`
    width: 100%;
    margin: 0;
    padding: ${({ theme }) => `${theme.sizeGridSpacingRem16} 0`};
    float: right;
    line-height: 1;
    font-size: ${({ theme }) => `${theme.sizeTextOverlayTitleSmall}`};

    @media (min-width: ${({ theme }) => `${theme.breakpointSmall}px`}) {
        max-width: ${({ theme }) => theme.sizeGridLayoutMaxWidthL};
    }
`;

const TextBlockWithQuote = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: start;
    justify-content: space-between;

    & > * {
        flex-grow: 1;
        width: 100%;
    }
`;

const ListWrapper = styled.div`
    text-align: left;
`;

const UnorderedList = styled.ul`
    margin-block-start: 0;
    list-style-type: none;

    li:before {
        content: '\2014';
    }

    &:last-child {
        margin-block-end: 0;
    }
`;

const OrderedList = styled.ol`
    margin-block-start: 0;

    &:last-child {
        margin-block-end: 0;
    }
`;

const TextBlockWrapper = styled.div<{ $isQuote?: boolean; $nextIsQuote?: boolean }>`
    @media (min-width: ${({ theme }) => `${theme.breakpointSmall}px`}) {
        display: flex;
    }

    & > * {
        flex-grow: 1;
    }
`;

const IFrameContainer = styled.div`
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    margin-top: ${({ theme }) => theme.sizeGridLayoutRem64};
    margin-bottom: ${({ theme }) => theme.sizeGridLayoutRem64};

    & iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`;